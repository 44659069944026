// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    appMessageThrow,
    appRedirect,
    paymentCheckoutCouponError,
    paymentCheckoutLoaded,
    paymentCheckoutSubmitPreviewFinish,
} from '../actions';
import {fetchJSON} from "../../helpers/api";

function* handle(error) {
    switch (error.status_code) {
        case 404:
            yield put(appRedirect('/error-404'));
            break;

        case 403:
        case 422:
            yield put(appMessageThrow(error.message, 'warning'));
            break;

        case 503: // Service Unavailable
            yield put(appMessageThrow(error.message, 'warning'));
            break;

        default:
            yield put(appRedirect('/error-500'));
            break;
    }
}

/**
 * Watch checkout initial load
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchPaymenCheckoutLoad(): any {
    yield takeEvery('PAYMENT_CHECKOUT_LOAD', function*({payload: {domainId, id}}) {
        try {
            const options = {
                method: 'GET',
            };

            const response = yield call(fetchJSON, `/domains/${domainId}/checkout/${id}`, options);
            yield put(paymentCheckoutLoaded(response));
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch checkout submit coupon
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchPaymenCheckoutSubmitCoupon(): any {
    yield takeEvery('PAYMENT_CHECKOUT_SUBMIT_COUPON', function*({payload: {coupon, billing, domainId, id}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    promotion_codes: [coupon],
                    billing_address: billing || null,
                }),
            };

            const response = yield call(fetchJSON, `/domains/${domainId}/checkout/${id}`, options);

            yield put(paymentCheckoutSubmitPreviewFinish(response));
        } catch (error) {
            if (error.message) {
                yield put(paymentCheckoutCouponError(error.message));
            } else {
                yield handle(error);
            }
        }
    });
}

/**
 * Watch checkout remove coupon
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchPaymenCheckoutRemoveCoupon(): any {
    yield takeEvery('PAYMENT_CHECKOUT_REMOVE_COUPON', function*({payload: {id, domainId, billing}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    promotion_codes: [],
                    billing_address: billing || null,
                }),
            };

            const response = yield call(fetchJSON, `/domains/${domainId}/checkout/${id}`, options);

            yield put(paymentCheckoutSubmitPreviewFinish(response));
        } catch (error) {
            if (error.message) {
                yield put(paymentCheckoutCouponError(error.message));
            } else {
                yield handle(error);
            }
        }
    });
}


/**
 * Watch checkout submit coupon
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchPaymenCheckoutSubmitPreview(): any {
    yield takeEvery('PAYMENT_CHECKOUT_SUBMIT_PREVIEW', function*({payload: {id, domainId, billingDetails}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    billing_address: billingDetails || null,
                }),
            };

            const response = yield call(fetchJSON, `/domains/${domainId}/checkout/${id}`, options);

            yield put(paymentCheckoutSubmitPreviewFinish(response));
        } catch (error) {
            // error just ignore
            console.warn(error);
            // yield handle(error);
        }
    });
}

function* PaymentCheckoutSaga(): any {
    yield all(
        [
            fork(watchPaymenCheckoutLoad),
            fork(watchPaymenCheckoutSubmitCoupon),
            fork(watchPaymenCheckoutRemoveCoupon),
            fork(watchPaymenCheckoutSubmitPreview),
        ]
    );
}

export default PaymentCheckoutSaga;
