// @flow
import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    translations: [],
    pagination: {
        page: 0,
        limit: 20,
        total: 0
    },
    filters: {
        languages: [],
        original: '',
        translation: '',
        edited: false,
        sortDirection: 'desc',
        exactOri: false,
        eaxctTrans: false,
    },
    loading: true,
};

type State = { type?: string };

const Translations = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'TRANSLATIONS_LOAD':
            return {...state, loading: true};

        case 'TRANSLATIONS_LOADED':
            return {...state, loading: false, translations: action.payload.translations, pagination: action.payload.pagination};

        case 'TRANSLATIONS_CHANGE_INPUT':
            const result = /(.*)\.(.*)?/.exec(action.payload.name);
            if (result) {
                const new_state = update(state, {
                    [result[1]]: {
                        [result[2]]: {
                            $set: action.payload.value
                        }
                    }
                });
                return {...new_state};
            }
            return update(state, {filters: {[action.payload.name]: {$set: action.payload.value}}});

        default:
            return {...state};
    }
};

export default Translations;
