// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load checkout info
 * @type: Action
 */
export const paymentCheckoutLoad = (id: number, domainId: number): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_LOAD',
    payload: {id, domainId},
});

/**
 * Loaded checkout info
 * @type: Event
 */
export const paymentCheckoutLoaded = (data): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_LOADED',
    payload: data,
});

/**
 * Initiate checkout submit
 * @type: Event
 */
export const paymentCheckoutSubmit = (): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_SUBMIT',
    payload: null,
});

/**
 * Checkout submit finish
 * @type: Event
 */
export const paymentCheckoutSubmitFinish = (): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_SUBMIT_FINISH',
    payload: null,
});

/**
 * Submit coupon discount
 * @type: Event
 */
export const paymentCheckoutSubmitCoupon = (id: number, domainId: number, couponCode: string, billingDetails): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_SUBMIT_COUPON',
    payload: {
        id,
        domainId,
        coupon: couponCode,
        billing: billingDetails,
    },
});

/**
 * Checkout remove coupon request
 * @type: Event
 */
export const paymentCheckoutRemoveCoupon = (id: number, domainId: number, billingDetails): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_REMOVE_COUPON',
    payload: {
        id,
        domainId,
        billing: billingDetails,
    },
});

/**
 * Checkout coupon error
 * @type: Event
 */
export const paymentCheckoutCouponError = (error): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_SUBMIT_COUPON_ERROR',
    payload: error,
});

/**
 * Do a checkout preview information
 * @type: Event
 */
export const paymentCheckoutSubmitPreview = (id: number, domainId: number, billingDetails): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_SUBMIT_PREVIEW',
    payload: {
        id,
        domainId,
        billingDetails,
    },
});

/**
 * Update checkout submit preview finish
 * @type: Event
 */
export const paymentCheckoutSubmitPreviewFinish = (newData): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_SUBMIT_PREVIEW_FINISH',
    payload: newData,
});

/**
 * Update checkout finalized data
 * @type: Event
 */
export const paymentCheckoutFinalized = (newData): ReduxActionType => ({
    type: 'PAYMENT_CHECKOUT_FINALIZED',
    payload: newData,
});
