// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load subscriptions
 * @type: Action
 */
export const subscriptionPlansLoad = (): ReduxActionType => ({
    type: 'SUBSCRIPTION_PLANS_LOAD',
    payload: null,
});

/**
 * Billing loaded
 * @type: Event
 */
export const subscriptionPlansLoaded = (data: {}): ReduxActionType => ({
    type: 'SUBSCRIPTION_PLANS_LOADED',
    payload: data,
});

/**
 * Save a subscriptions
 * @type: Action
 */
export const updateDomainSubscription = (id, plan): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_UPDATE',
    payload: {id, plan},
});

/**
 * Redirect to Stripe portal
 * @type: Action
 */
export const redirectPortal = (id): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_REDIRECT_PORTAL',
    payload: {id},
});

/**
 * Request failed
 * @type: Event
 */
export const subscriptionPlansRequestFailed = (): ReduxActionType => ({
    type: 'SUBSCRIPTION_PLANS_REQUEST_FAILED',
    payload: null,
});

/**
 * Save a subscriptions
 * @type: Action
 */
export const subscriptionsPaypalInitiate = (checkout, plan): ReduxActionType => ({
    type: 'SUBSCRIPTION_PAYPAL_INITIATE',
    payload: {checkout, plan},
});

/**
 * Save a subscriptions
 * @type: Action
 */
export const subscriptionsPaypalFinish = (plan): ReduxActionType => ({
    type: 'SUBSCRIPTION_PAYPAL_FINISH',
    payload: {plan},
});

export const domainSubscriptionInfoLoad = (domainId): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_INFO_LOAD',
    payload: domainId,
});

export const domainSubscriptionInfoLoaded = (data): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_INFO_LOADED',
    payload: data,
});

export const domainSubscriptionInfoRequestFailed = (): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_INFO_REQUEST_FAILED',
    payload: null,
});

export const domainSubscriptionInfoDelete = (): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_INFO_DELETE',
    payload: null,
});

export const domainSubscriptionInfoUpdate = (id, subsId, payload): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_INFO_UPDATE',
    payload: {
        id,
        subsId,
        payload,
    },
});

export const domainSubscriptionInfoUpdated = (data): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_INFO_UPDATED',
    payload: data,
});
