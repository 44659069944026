// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";
import type {DomainType} from "../../flow/DomainType";

const INITIAL_STATE = {
    loading: true,
    reloading: false,
    connected: false,
    domains: [],
    domain: null,
    locales: [],
    user: null,
    message: null,
    redirect: null,
    query: null,
    accounts: [],
    roles: [],
    entri_success: false,
    // List of paypal billings info
    // TODO: Change it back to default payment checking later.
    billingsInfo: [],
};

type State = { type?: string };

const App = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'APP_LOADED': {
            return {
                ...state,
                loading: false,
                connected: true,
                ...action.payload,
                accounts: state.accounts,
                domain: null
            };
        }

        case 'APP_RELOAD': {
            return {
                ...state,
                reloading: true,
            }
        }

        case 'APP_RELOADED': {
            return {
                ...state,
                loading: false,
                connected: true,
                reloading: false,
                ...action.payload,
                accounts: state.accounts,
            };
        }

        case 'APP_USER_NOT_CONNECTED': {
            return {...state, loading: false, connected: false, user: null};
        }

        case 'APP_CHANGE_DOMAIN': {
            return {
                ...state,
                domain: state.domains.length && action.payload
                    ? state.domains.find((d) => d.id === parseInt(action.payload)) || null
                    : null
            };
        }

        case 'APP_DOMAINS_LOADED': {
            return {...state, loading: false, domains: action.payload.domains};
        }

        case 'APP_MESSAGE_THROW': {
            return {...state, message: {message: action.payload.message, type: action.payload.type}};
        }

        case 'APP_MESSAGE_THROWN': {
            return {...state, message: null};
        }

        case 'APP_REDIRECT': {
            return {...state, redirect: action.payload};
        }

        case 'APP_REDIRECT_WITH_QUERY': {
            return {...state, redirect: action.payload.redirect, query: action.payload.query};
        }

        case 'APP_REDIRECTED': {
            return {...state, redirect: null, query: null};
        }

        case 'APP_USER_UPDATED': {
            return {...state, user: {...state.user, ...action.payload}};
        }

        case 'APP_DOMAIN_UPDATED': {
            const updatedDomain: DomainType = action.payload;
            return {...state, domains: state.domains.map((d: DomainType) => (d.id === updatedDomain.id ? updatedDomain : d))};
        }
        case 'APP_ENTRI_SUCCESS': {
            return {...state, entri_success: action.payload};
        }

        case 'APP_BILLINGS_INFO_UPDATED': {
            const billing = action.payload;
            const findIndex = state.billingsInfo.findIndex((b) => b.id === billing.id);

            if (findIndex === -1) {
                return {...state, billingsInfo: [...state.billingsInfo, billing]};
            } else {
                return {...state, billingsInfo: state.billingsInfo.map((b) => (b.id === billing.id ? billing : b))};
            }
        }

        case 'APP_DOMAIN_SUBSCRIPTION_UPDATED': {
            const updatedDomain = action.payload;

            const domains = state.domains;
            const domainIdx = domains.findIndex((d) => d.id === updatedDomain.domain_id);

            if (domainIdx !== -1) {
                const domain = domains[domainIdx];

                if (domain.subscription) {
                    domain.subscription.stripe_status = updatedDomain.status;
                    domain.subscription.canceled_at_end_period = updatedDomain.is_canceling;
                    domain.subscription.end_period = updatedDomain.ends_at;

                    if (updatedDomain.plan) {
                        domain.subscription.stripe_plan = updatedDomain.plan.stripe_id;
                        domain.subscription.price = updatedDomain.plan.price;
                        domain.subscription.interval = updatedDomain.plan.interval;
                        domain.subscription.interval_count = updatedDomain.plan.interval_count;
                        domain.subscription.plan = updatedDomain.plan.name;
                    }

                    if (updatedDomain.billing) {
                        domain.subscription.billing = updatedDomain.billing;
                    }
                }

                domains[domainIdx] = domain;
            }

            return {
                ...state,
                domains,
            }
        }


        case 'ACCOUNTS_LOADED': {
            return {...state, accounts: action.payload};
        }

        case 'ACCOUNT_ADDED': {
            return {...state, accounts: [...state.accounts, action.payload]};
        }

        default: {
            return {...state};
        }
    }
};

export default App;
