// @flow
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {
    appDomainUpdated,
    appMessageThrow,
    appRedirect,
    domainSubscriptionInfoLoaded,
    domainSubscriptionInfoRequestFailed,
    domainSubscriptionInfoUpdated,
    subscriptionPlansLoaded,
    subscriptionPlansRequestFailed,
} from '../actions';
import {fetchJSON} from "../../helpers/api";
import {appDomainSubscriptionUpdated, appRedirectWithQuery} from "../app/actions";
import {route} from "../../helpers/common";
import type {DomainType} from "../../flow/DomainType";

/**
 * Watch load subscriptions request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchSubscriptionsLoad(): any {
    yield takeEvery('SUBSCRIPTION_PLANS_LOAD', function*() {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/plans', options);
            yield put(subscriptionPlansLoaded(response));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(subscriptionPlansRequestFailed());
                yield put(appRedirect('/'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save an account
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchSubscriptionsUpdate(): any {
    yield takeEvery('DOMAIN_SUBSCRIPTION_UPDATE', function*({payload: {id, plan}}) {
        try {
            const referalId = window.FPROM && window.FPROM.data.tid || ('fp_'+(new Date()).getTime());

            const options = {
                body: JSON.stringify({plan, referalId}),
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/domains/${id}/subscription`, options);
            if (response.checkout) {
                // Redirect to the checkout page
                const {id, account_id, domain_id} = response.checkout;
                yield put(appRedirect(route('account.subscriptions.checkout', {
                    account_id,
                    domain_id,
                    id,
                })));
            } else {
                const {id, account_id}: DomainType = response;
                yield put(appRedirectWithQuery(route('account.subscriptions', {account_id}), `?domain_subscribed=${id}`));
                yield put(appDomainUpdated(response));
            }

        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(subscriptionPlansRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

export function* watchSubscriptionsRedirectPortal(): any {
    yield takeEvery('DOMAIN_SUBSCRIPTION_REDIRECT_PORTAL', function*({payload: {id}}) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            // Redirect to the portal
            window.location = yield call(fetchJSON, `/accounts/${id}/billing/portal`, options);
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(subscriptionPlansRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

export function* watchDomainSubscriptionInfoLoad(): any {
    yield takeEvery('DOMAIN_SUBSCRIPTION_INFO_LOAD', function*({payload: id}) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const result = yield call(fetchJSON, `/domains/${id}/subscription`, options);
            yield put(domainSubscriptionInfoLoaded(result));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422 || error.status_code === 400) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(domainSubscriptionInfoRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

export function* watchDomainSubscriptionInfoUpdate(): any {
    yield takeEvery('DOMAIN_SUBSCRIPTION_INFO_UPDATE', function*({payload: {id, subsId, payload}}) {
        try {
            const options = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            };

            const result = yield call(fetchJSON, `/domains/${id}/subscription/${subsId}`, options);
            yield put(domainSubscriptionInfoUpdated(result));
            yield put(appDomainSubscriptionUpdated(result));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422 || error.status_code === 400) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(domainSubscriptionInfoRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

function* SubscriptionsSaga(): any {
    yield all(
        [
            fork(watchSubscriptionsLoad),
            fork(watchSubscriptionsUpdate),
            fork(watchSubscriptionsRedirectPortal),
            fork(watchDomainSubscriptionInfoLoad),
            fork(watchDomainSubscriptionInfoUpdate),
        ]
    );
}

export default SubscriptionsSaga;
