// @flow
import {allFlattenRoutes} from "../routes/index";
import {generatePath} from "react-router-dom";
import type {RouteType} from "../flow/RouteType";
import {stringify} from "query-string";
import { loadStripe } from "@stripe/stripe-js";
import { enUS as dateEn, de as dateDe, es as dateEs, fr as dateFr, it as dateIt, ja as dateJa, nl as dateNl, ru as dateRu, vi as dateVi } from "date-fns/locale";
import { format } from "date-fns";
import { sanitizeUrl } from "@braintree/sanitize-url";
import sanitizeHTML from "sanitize-html";

if (!process.env.REACT_APP_STRIPE_KEY) {
    throw new Error('Stripe key is required!');
}

/**
 * The global shared Stripe object.
 * 
 * @type {Promise<import('@stripe/stripe-js').Stripe | null>}
 */
const globalStripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

/**
 * Returns a promise resolving to the Stripe object.
 *
 * @returns {Promise<import('@stripe/stripe-js').Stripe | null>}
 */
export async function getStripe() {
    // Check by .then function signature for a "PromiseLike"
    if (typeof globalStripe.then === 'function') {
        // load stripe
        return await globalStripe;
    }

    // Already loaded
    return globalStripe;
}

export const capitalize = (str: string) => typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;

export const formatBytes = (a, b) => {
    if (0 === a) return "0 Bytes";

    const c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(a) / Math.log(c));

    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]
};

export const nFormatter = (num, digits) => {
    const si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "K" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const route = (name, params, query) => {
    const url = generatePath(allFlattenRoutes.find((r: RouteType) => r.routeName === name).path, params);
    return query ? `${url}?${stringify(query)}` : url;
};

export const formatLocalizedDate = (date: string | Date, lang: string) => {
    const locale = shortLangCode(lang);

    const localeSelector = {
        en: dateEn,
        de: dateDe,
        es: dateEs,
        fr: dateFr,
        it: dateIt,
        ja: dateJa,
        nl: dateNl,
        ru: dateRu,
        vi: dateVi,
    };

    const selectedLocale = localeSelector[locale] || dateEn;
    return format(new Date(date), 'PPP', {locale: selectedLocale})
}

export const shortLangCode = (lang: string) => typeof lang === 'string' ? (lang.toLowerCase().split('-'))[0] : lang;

/**
 * Format revenue number according to Stripe currency formatting
 *
 * Reference: https://docs.stripe.com/currencies#presentment-currencies
 *
 * @param {number} amount
 * @param {string} currency
 * @return {number}
 */
export function formatStripeAmount(amount, currency) {
    if (!amount || !currency) {
        return amount;
    }

    // https://docs.stripe.com/currencies#zero-decimal
    const zeroDecimal = [
        'BIF',
        'CLP',
        'DJF',
        'GNF',
        'JPY',
        'KMF',
        'KRW',
        'MGA',
        'PYG',
        'RWF',
        'UGX',
        'VND',
        'VUV',
        'XAF',
        'XOF',
        'XPF',
    ];

    if (zeroDecimal.includes(currency.toUpperCase())) {
        return amount;
    }

    return amount / 100;
}

/**
 * Sanitizes a URL and returns the sanitized version,
 * or null if the URL is invalid.
 *
 * @param {string} url
 * @returns {URL | null}
 */
export const urlSanitizer = (url) => {
    /** @type {URL} */
    let urlVerify;

    try {
        urlVerify = new URL(url);
    } catch {
        return null;
    }

    const preSanitize = sanitizeUrl(urlVerify.href);
    if (preSanitize === 'about:blank') {
        return null;
    }

    const parsedUrl = new URL(preSanitize);

    // Go through all query param
    parsedUrl.searchParams.forEach((value, key) => {
        // Sanitize each to remove unsafe HTML
        parsedUrl.searchParams.set(key, sanitizeHTML(value));
    });

    return parsedUrl;
}
