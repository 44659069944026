// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    accountId: null,
    paymentMethods: [],
    paypalPromoUsed: false,
    loading: true,
    saving: false,
    loadingIds: null,

    currentCards: [],
    loadingCards: false,

    currentDetail: null,
    loadingDetail: true,
    submitDetail: false,
};

const AccountBillings = (state = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'ACCOUNT_BILLINGS_LOAD': {
            return {...INITIAL_STATE};
        }

        case 'ACCOUNT_BILLINGS_LOADED': {
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        }

        case 'ACCOUNT_BILLINGS_REQUEST_FAILED': {
            return {
                ...state,
                loadingIds: null,
                loading: false,
                saving: false,
            };
        }

        case 'ACCOUNT_BILLINGS_PAYPAL_BIND': {
            return {
                ...state,
                saving: true,
            };
        }

        case 'ACCOUNT_BILLINGS_STRIPE_BIND': {
            return {
                ...state,
                saving: true,
            };
        }

        case 'ACCOUNT_BILLINGS_BIND_FINISH': {
            return {
                ...state,
                saving: false,
            };
        }

        case 'ACCOUNT_BILLINGS_UPDATE_SINGLE': {
            const findPayment = state.paymentMethods.findIndex((method) => method.id === action.payload.id);

            if (findPayment === -1) {
                return state;
            }

            const copyState = {...state};
            copyState.paymentMethods[findPayment] = action.payload;

            return {
                ...state,
                ...copyState,
            };
        }

        case 'ACCOUNT_BILLINGS_SET_DEFAULT': {
            return {
                ...state,
                loadingIds: action.payload.paymentId,
            };
        }

        case 'ACCOUNT_BILLINGS_DEFAULT_SET': {
            return {
                ...state,
                loadingIds: null,
            };
        }

        case 'ACCOUNT_BILLINGS_SET_FALLBACK': {
            return {
                ...state,
                loadingIds: action.payload.paymentId,
            };
        }

        case 'ACCOUNT_BILLINGS_FALLBACK_SET': {
            return {
                ...state,
                loadingIds: null,
            };
        }

        case 'ACCOUNT_BILLINGS_DELETE': {
            return {
                ...state,
                loadingIds: action.payload.paymentId,
            };
        }

        case 'ACCOUNT_BILLINGS_DELETED': {
            const findPayment = state.paymentMethods.findIndex((method) => method.id === action.payload.paymentId);
            if (findPayment === -1) {
                return state;
            }

            const copyState = {...state};
            copyState.paymentMethods.splice(findPayment, 1);

            return {
                ...state,
                ...copyState,
                loadingIds: null,
            };
        }

        case 'ACCOUNT_BILLINGS_LOAD_SINGLE': {
            return {
                ...state,
                loadingCards: true,
                currentCards: [],
            }
        }

        case 'ACCOUNT_BILLINGS_LOADED_SINGLE': {
            const findPayment = state.paymentMethods.findIndex((method) => method.id === action.payload.paymentMethod.id);

            const copyState = {...state};

            if (findPayment === -1) {
                copyState.paymentMethods.push(action.payload.paymentMethod);
            }

            copyState.paymentMethods[findPayment] = action.payload.paymentMethod;

            return {
                ...state,
                ...copyState,
                loadingCards: false,
                currentCards: action.payload.subscriptions,
            }
        }

        case 'ACCOUNT_BILLING_SINGLE_EDIT_LOAD': {
            return {
                ...state,
                loadingDetail: true,
                currentDetail: null,
            }
        }

        case 'ACCOUNT_BILLING_SINGLE_EDIT_LOADED': {
            return {
                ...state,
                loadingDetail: false,
                currentDetail: action.payload,
            }
        }

        case 'ACCOUNT_BILLING_SINGLE_EDIT_SUBMIT': {
            return {
                ...state,
                submitDetail: true,
            }
        }

        case 'ACCOUNT_BILLING_SINGLE_EDIT_SUBMIT_FINISH': {
            const findPayment = state.paymentMethods.findIndex((method) => method.id === action.payload.billing.id);

            const copyState = {...state};
            if (findPayment !== -1) {
                copyState.paymentMethods[findPayment] = action.payload.billing;
            }

            return {
                ...state,
                currentDetail: action.payload.currentDetail,
                submitDetail: false,
            }
        }

        case 'ACCOUNT_BILLING_SINGLE_STOP_SUBMIT_LOAD': {
            return {
                ...state,
                submitDetail: false,
            }
        }

        default:
            return state;
    }
};

export default AccountBillings;
