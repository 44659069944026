// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    loading: true,
    submit: false,
    submitCoupon: false,

    errorCoupon: null,

    // The actual data from API
    data: {
        id: 0,
        account_id: 0,
        domain_id: 0,
        stripe_id: null, // can be null
        token: null,
        invoice: {},
        address: null,
        plan: null,
        payment_methods: [],
        supports: [],
        paypal: null,
    }
};

const PaymentCheckout = (state = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'PAYMENT_CHECKOUT_LOAD': {
            return {...INITIAL_STATE};
        }

        case 'PAYMENT_CHECKOUT_LOADED': {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        }

        case 'PAYMENT_CHECKOUT_SUBMIT': {
            return {
                ...state,
                submit: true,
            };
        }

        case 'PAYMENT_CHECKOUT_SUBMIT_FINISH': {
            return {
                ...state,
                submit: false,
            };
        }

        case 'PAYMENT_CHECKOUT_SUBMIT_COUPON': {
            return {
                ...state,
                errorCoupon: null,
                submitCoupon: true,
            };
        }

        case 'PAYMENT_CHECKOUT_REMOVE_COUPON': {
            return {
                ...state,
                errorCoupon: null,
                submitCoupon: true,
            };
        }

        case 'PAYMENT_CHECKOUT_SUBMIT_PREVIEW': {
            return {
                ...state,
                submitCoupon: true,
            };
        }

        case 'PAYMENT_CHECKOUT_SUBMIT_COUPON_ERROR': {
            return {
                ...state,
                errorCoupon: action.payload,
                submitCoupon: false,
            };
        }

        case 'PAYMENT_CHECKOUT_SUBMIT_PREVIEW_FINISH': {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                submitCoupon: false,
            };
        }

        case 'PAYMENT_CHECKOUT_FINALIZED': {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                }
            }
        }

        default:
            return state;
    }
};

export default PaymentCheckout;
