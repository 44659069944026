// @flow

import { combineReducers } from 'redux';
import App from './app/reducers';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Rules from './rules/reducers';
import Rule from './rule/reducers';
import Domain from './domain/reducers';
import Translations from './translations/reducers';
import Translation from './translation/reducers';
import SearchTranslations from './searchTranslations/reducers';
import Urls from './urls/reducers';
import Url from './url/reducers';
import Statistics from './statistics/reducers';
import Account from './account/reducers';
import Invoices from './invoices/reducers';
import Billing from './billing/reducers';
import Subscriptions from "./subscriptions/reducers";
import AccountBillings from './accountBillings/reducers';
import AccountMembers from "./accountMembers/reducers";
import PaymentCheckout from './checkouts/reducers';
import Links from './links/reducers';
import Link from './link/reducers';

export default combineReducers({
    App,
    Auth,
    AppMenu,
    Layout,
    Rules,
    Rule,
    Domain,
    Translations,
    Translation,
    SearchTranslations,
    Urls,
    Url,
    Statistics,
    Account,
    Invoices,
    Billing,
    Subscriptions,
    AccountBillings,
    AccountMembers,
    PaymentCheckout,
    Links,
    Link,
});
