// @flow

import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    subscription: null,
    plans: [],
    saving: false,
    paypalPlan: null,
    loading: true,
    loadingSubs: true,
    savingSubs: false,
};

type State = { type?: string };

const Subscriptions = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'SUBSCRIPTION_PLANS_LOAD':
            return {...INITIAL_STATE};

        case 'SUBSCRIPTION_PLANS_LOADED':
            return {
                ...state,
                plans: action.payload,
                loading: false,
            };

        case 'DOMAIN_SUBSCRIPTION_UPDATE':
            return {...state, saving: true};

        case 'SUBSCRIPTION_PLANS_REQUEST_FAILED':
            return {...state, saving: false};

        case 'SUBSCRIPTION_PAYPAL_INITIATE': {
            return {
                ...state,
                paypalPlan: action.payload,
                saving: false,
            }
        }

        case 'SUBSCRIPTION_PAYPAL_FINISH': {
            return {
                ...state,
                paypalPlan: null,
            }
        }

        case 'DOMAIN_SUBSCRIPTION_INFO_LOAD':
            return {...state, loadingSubs: true};

        case 'DOMAIN_SUBSCRIPTION_INFO_LOADED':
            return {...state, loadingSubs: false, subscription: action.payload};

        case 'DOMAIN_SUBSCRIPTION_INFO_UPDATE':
            return {...state, savingSubs: true};

        case 'DOMAIN_SUBSCRIPTION_INFO_UPDATED':
            if (!state.subscription) {
                return {...state, loadingSubs: false, savingSubs: false, subscription: action.payload};
            }

            const mergedSubscription = {
                ...state.subscription,
                ...action.payload,
            };
            return {...state, loadingSubs: false, savingSubs: false, subscription: mergedSubscription};

        case 'DOMAIN_SUBSCRIPTION_INFO_REQUEST_FAILED':
            return {...state, loadingSubs: false};

        case 'DOMAIN_SUBSCRIPTION_INFO_DELETE':
            return {...state, subscription: null};

        default:
            return state;
    }
};

export default Subscriptions;
