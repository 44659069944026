// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";
// @flow
import type {AccountBillingType} from "../../flow/AccountBillingType";

/**
 * Load account billings
 * @type: Action
 */
export const accountBillingsLoad = (id: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_LOAD',
    payload: id,
});

/**
 * Account billings loaded
 * @type: Event
 */
export const accountBillingsLoaded = (paymentMethods: AccountBillingType[]): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_LOADED',
    payload: paymentMethods,
});

/**
 * Account billings request failed
 * @type: Event
 */
export const accountBillingsRequestFailed = (): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_REQUEST_FAILED',
    payload: null,
});

/**
 * Update single payment method
 */
export const accountBillingUpdateSingle = (paymentMethod: AccountBillingType): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_UPDATE_SINGLE',
    payload: paymentMethod,
});

/**
 * Load/initiate paypal binding
 * @type Event
 */
export const accountBillingsPaypalBind = (accountId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_PAYPAL_BIND',
    payload: {
        accountId
    },
});

/**
 * Load/initiate stripe binding
 * @type Event
 */
export const accountBillingsStripeBind = (accountId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_STRIPE_BIND',
    payload: {
        accountId
    },
});


/**
 * Binding finish
 */
export const accountBillingsBindFinish = (): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_BIND_FINISH',
    payload: null,
});

/**
 * Set default payment method
 * @type Event
 */
export const accountBillingsSetDefault = (accountId: number, paymentId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_SET_DEFAULT',
    payload: {
        accountId,
        paymentId,
    },
});

/**
 * Set default payment method success
 */
export const accountBillingsDefaultSet = (): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_DEFAULT_SET',
    payload: null,
});


/**
 * Set fallback payment method
 * @type Event
 */
export const accountBillingsSetFallback = (accountId: number, paymentId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_SET_FALLBACK',
    payload: {
        accountId,
        paymentId,
    },
});

/**
 * Set fallback payment method success
 */
export const accountBillingsFallbackSet = (): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_FALLBACK_SET',
    payload: null,
});

/**
 * Delete account billing
 * @type Event
 */
export const accountBillingsDelete = (accountId: number, paymentId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_DELETE',
    payload: {
        accountId,
        paymentId,
    },
});

/**
 * Account billing deleted
 */
export const accountBillingsDeleted = (paymentId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_DELETED',
    payload: {
        paymentId,
    },
});


/**
 * Load single account billing
 * @type: Action
 */
export const accountBillingsLoadSingle = (id: number, accountId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_LOAD_SINGLE',
    payload: {id, accountId},
});

/**
 * Single account billing loaded
 * @type: Event
 */
export const accountBillingsLoadedSingle = (paymentMethod: AccountBillingType, subscriptions: any[]): ReduxActionType => ({
    type: 'ACCOUNT_BILLINGS_LOADED_SINGLE',
    payload: {
        paymentMethod,
        subscriptions,
    },
});

/**
 * Load single account billing edit data
 * @type: Action
 */
export const accountBillingsSingleEditLoad = (id: number, accountId: number): ReduxActionType => ({
    type: 'ACCOUNT_BILLING_SINGLE_EDIT_LOAD',
    payload: {id, accountId},
});

/**
 * Single account billing edit data loaded
 * @type: Event
 */
export const accountBillingsSingleEditLoaded = (currentDetail): ReduxActionType => ({
    type: 'ACCOUNT_BILLING_SINGLE_EDIT_LOADED',
    payload: currentDetail,
});

/**
 * Save single account billing edit data
 * @type: Event
 */
export const accountBillingsSingleEdiSubmit = (id: number, accountId: number, currentDetail): ReduxActionType => ({
    type: 'ACCOUNT_BILLING_SINGLE_EDIT_SUBMIT',
    payload: {
        id,
        accountId,
        currentDetail,
    },
});

/**
 * Save single account billing edit data
 * @type: Event
 */
export const accountBillingsSingleEdiSubmitFinish = (billing, currentDetail): ReduxActionType => ({
    type: 'ACCOUNT_BILLING_SINGLE_EDIT_SUBMIT_FINISH',
    payload: {
        billing,
        currentDetail,
    },
});

/**
 * Save single account billing edit data
 * @type: Event
 */
export const accountBillingsStopSubmitLoad = (): ReduxActionType => ({
    type: 'ACCOUNT_BILLING_SINGLE_STOP_SUBMIT_LOAD',
    payload: null,
});