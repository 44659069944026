// @flow
import {all, call, fork, put, delay, takeEvery, take} from 'redux-saga/effects';

import {
    appLoaded,
    appChangeDomain,
    appDomainsLoaded,
    appMessageThrown,
    accountsLoad,
    appRedirect,
    appReloaded,
} from '../actions';

import {fetchJSON} from "../../helpers/api";
import {editorRedirect} from "../../helpers/editor";
import {route} from "../../helpers/common";

/**
 * Watch load app request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAppLoad(): any {
    yield takeEvery('APP_LOAD', function*({ payload: domain_id  }) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/user/status', options);

            yield put(accountsLoad());
            yield take('ACCOUNTS_LOADED');

            yield put(appLoaded(response));

            if (domain_id) {
                // See note in src/redux/domain/saga.js:watchDomainSave
                if (domain_id !== -1) {
                    yield put(appChangeDomain(domain_id));
                }
            } else if (!response.domains.length) {
                yield put(appRedirect(route('domain.add')));
            } else {
                yield put(appRedirect('/'));
            }
        } catch (error) {
            console.error(error);
        }
    });
}

/**
 * Watch reload app request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAppReload(): any {
    yield takeEvery('APP_RELOAD', function*() {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/user/status', options);

            yield put(appReloaded(response));
        } catch (error) {
            console.error(error);
        }
    })
}

/**
 * Watch load domain request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAppDomainsLoad(): any {
    yield takeEvery('APP_DOMAINS_LOAD', function*() {
        try {
            const response = yield call(fetchJSON, '/domains', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            yield put(appDomainsLoaded(response.data));
        } catch (error) {
            console.warn(error);
        }
    });
}

/**
 * Watch message to displayu
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAppMessageThrow(): any {
    yield takeEvery('APP_MESSAGE_THROW', function*() {
        yield delay(5000); // Display the message during 2 seconds
        yield put(appMessageThrown());
    });
}

/**
 * Watch redirection to the frontend editor
 *
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAppFrontendEditor(): any {
    yield takeEvery('APP_FRONTEND_EDITOR', function*({ payload: {domain_id, language}  }) {
        try {
            const options = {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },
            };

            if (language !== '') {
              options.search = {
                language,
              };
            }

            const response = yield call(fetchJSON, '/domains/' + domain_id + '/editor/redirect', options);

            yield call(editorRedirect, response);

        } catch (error) {
            console.warn(error);
        }
    });
}

function* AppSaga(): any {
    yield all([
        fork(watchAppLoad),
        fork(watchAppReload),
        fork(watchAppDomainsLoad),
        fork(watchAppMessageThrow),
        fork(watchAppFrontendEditor),
    ]);
}

export default AppSaga;
