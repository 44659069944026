const Constants = {
  RULE_TYPE_TEXT_IGNORE : 1,
  RULE_TYPE_TEXT_REPLACE : 2,
  RULE_TYPE_CONTENT_EXCLUSION : 3,
  RULE_TYPE_URL_EXCLUSION : 4,
  RULE_TYPE_IGNORE_INLINE : 5,
};

export default Constants;

export const DATE_FORMAT = 'dd MMMM yyyy';

export const REGEX_LINK = (languages) => `/^(http|https)://(?:(www\.)?(?!.*/(${languages.join('|')})/)(?=[^\\s]*\\.(?!(html|php))[a-zA-Z0-9]+$)|[^\\s]+\/.*)$/`;

export const PLAN_DETAILS_KEYS = [
  'new_languages',
  'one_month_trial',
  'multi_content_indexed',
  'translate_new_content',
  'free_installation',
  'unlimited_languages',
];

export const FREQUENT_QUESTIONS_KEYS = [
  'trial',
  'seo',
  'quota',
];

/**
 * @type {import('@stripe/stripe-js').Appearance}
 */
export const STRIPE_ELEMENTS_APPEARANCE = {
  theme: 'stripe',
  variables: {
    fontFamily: 'Nunito, sans-serif',
    fontWeightLight: '200',
    fontWeightNormal: '400',
    fontWeightMedium: '500',
    fontWeightBold: '800',

    colorPrimary: '#4762fa',
    colorBackground: '#ffffff',
    colorSuccess: '#61ce70',
    colorDanger: '#ee4545',
    colorWarning: '#f05c0b',
    colorText: '#161616',
    colorTextSecondary: '#5a5b5c',
    colorTextPlaceholder: '##98a6ad',

    accessibleColorOnColorDanger: '#ffffff',
    accessibleColorOnColorSuccess: '#ffffff',
    accessibleColorOnColorWarning: '#ffffff',
  },
  rules: {
    '.Label': {
      color: 'var(--colorTextPlaceholder)',
      fontWeight: '600',
    },
  }
};

/**
 * @type {import('@stripe/stripe-js').CssFontSource}
 */
export const STRIPE_ELEMENTS_FONTS = {
  cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap',
};
