// @flow

import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    link: {
        id: 0,
        original: '',
        mode: 'exact',
        translations: {},
    },
    saving: false,
    saved: false,
    deleting: false,
    deleted: false,
};

type State = { type?: string };

const Link = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'LINK_CHANGE_INPUT':
            if (action.payload.type === 'translations') {
                const language = action.payload.name;

                return update(state, {link: { translations: {[language]: {$set: action.payload.value}} }});
            }
            return update(state, {link: {[action.payload.name]: {$set: action.payload.value}}});

        case 'LINK_SAVE':
            return {...state, saved: false, saving: true};

        case 'LINK_SAVED':
          return update(state, {link: {id: {$set: action.payload.id}}, saved: {$set: false}, saving: {$set: false}});

        case 'LINK_SAVED_AND_QUIT':
            return {...INITIAL_STATE, saved: true, saving: false};

        case 'LINK_DELETE':
            return {...state, deleting: true};

        case 'LINK_DELETED':
            return {...state, deleted: true};

        case 'LINK_CLOSED':
            return {...INITIAL_STATE};
            
        case 'LINK_LOADED':
            let link = INITIAL_STATE.link;

            // Case new link
            if (action.payload === null) {
                return {...state, link: link};
            }

            const translations = {};
            if (action.payload.translations.length > 0) {
                action.payload.translations.forEach((translation) => {
                    translations[translation.language] = translation.translation;
                })
            }

            const new_state = {...action.payload, translations: translations};

            return update(state, {link: {$set: new_state}});

        case 'LINK_REQUEST_FAILED':
            return {...state, saving: false};

        default:
            return {...state};
    }
};

export default Link;
