// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import tawkTo from "tawkto-react";

// Themes
import './assets/scss/DefaultTheme.scss';

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {
    render() {
        return <Routes></Routes>;

    }

    /**
     * Check if GTM is enabled, if not launch manually tawkto
     * @param timeout
     */
    checkGA(timeout = false) {
        if (window.google_tag_manager !== undefined) {
            return;
        } else {
            if (timeout) {
                tawkTo(process.env.REACT_APP_TAWKTO_PROPERTY_ID, process.env.REACT_APP_TAWKTO_KEY);
            } else {
                setTimeout(() => this.checkGA(true),4000);
            }
        }
    }

    attachEntri() {
        console.log('attaching entri');
        const entriJs = document.getElementById('entri-js');
        // Attach Entri JS if not already attached
        if (!entriJs && process.env.REACT_APP_ENTRI_AUTO_DNS === '1') {
            const script = document.createElement('script');
            script.src = 'https://cdn.goentri.com/entri.js';
            script.async = true;
            script.type = 'text/javascript';
            script.id = 'entri-js';
            document.body.appendChild(script);
        }
    }

    componentDidMount() {
        this.checkGA(false);
        this.attachEntri();
    }
}

export default App;
